import {
    CREATE_BUILDING,
    GET_BUILDINGS,
    UPDATE_BUILDING,
    DELETE_BUILDING,
  } from "../actions/types";
  
  const initialState = [];
  
  function buildingReducer(buildings = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CREATE_BUILDING:
        return [...buildings, payload];
  
      case GET_BUILDINGS:
        return payload;
  
      case UPDATE_BUILDING:
        return buildings.map((building) => {
          if (building.BuildingId === payload.BuildingId) {
            return {
              ...building,
              ...payload,
            };
          } else {
            return building;
          }
        });
  
      case DELETE_BUILDING:
        return buildings.filter(({ BuildingId }) => BuildingId !== payload.BuildingId);
  
      default:
        return buildings;
    }
  };
  
  export default buildingReducer;
  
