import {
    GET_CONTROLLERS
  } from "../actions/types";
  
  const initialState = [];
  
  function controllerReducer(controllers = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
        case GET_CONTROLLERS:
          return payload;
  
      default:
        return controllers;
    }
  };
  
  export default controllerReducer;
  
  