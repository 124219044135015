import "./sensor.css";
import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import sensorService from "../../services/sensorService";
import { useDispatch, useSelector } from "react-redux";
import { retrieveSensors, findSensorsByFilter, deleteSensor } from "../../store/actions/sensorAction";
import { CLEAR_MESSAGE } from "../../store/actions/types";
import apartmentService from "../../services/apartmentService";
import buildingService from "../../services/buildingService";
import roomService from "../../services/roomService";
import DropDown from "../../components/DropDown/DropDown";

//material ui
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SyncAltIcon from '@material-ui/icons/SyncAlt';

// Components:
import Label from "../../components/Label/Label";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { retrieveBuildings } from "../../store/actions/buildingAction";
import { retrieveApartments } from "../../store/actions/apartmentAction";

//material-ui
import AutorenewIcon from '@material-ui/icons/Autorenew';

export interface SensorListProps { }
const SensorsList: React.FC<SensorListProps> = () => {

  const sensors = useSelector((state: any) => state.sensorReducer);
  const buildings = useSelector((state: any) => state.buildingReducer);

  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState(false);
  const [active, setActive] = useState(false);

  const [searchTitle, setSearchTitle] = useState("");
  const [clickedSensor, setClickedSensor] = useState("");

  const [apartmentTitle, setapartmentTitle] = useState("");
  const [buildingTitle, setSavedBuildingTitle] = useState("");
  const [roomTitle, setRoomTitle] = useState([]);
  const [controllerTitle, setControllerTitle] = useState("");

  const [hanldeDelete, ishanldeDelete] = useState("");

  const [buildingFilter, setbuildingFilter] = useState("");
  const [apartmentFilter, setapartmentFilter] = useState("");

  const [buildingApartments, setbuildingApartments] = useState([]);

  const [resetFilter, isresetFilter] = useState(false);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(retrieveSensors());
    dispatch(retrieveBuildings());
    dispatch(retrieveApartments());
  }, [dispatch]);

  const onChangeSearchTitle = e => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
    refreshData();
    dispatch(findSensorsByFilter(searchTitle, buildingFilter, apartmentFilter));
    setActive(false);
  };

  const refreshData = () => {
    retrieveSensors();
  };

  const gotoSensorInfo = (sensor, index) => {
    sensorService.getSensor(sensor.SensorId)
      .then(response => {
        sensorService.getController(response.data.ControllerId)
          .then(response => {
            setControllerTitle(response.data.SerialNumber)
          })
        if (response.data.RoomId !== undefined) {
          roomService.getRoom(response.data.RoomId)
            .then(response => {
              setRoomTitle(response.data.Name)
            })
        }
        if (response.data.BuildingObjectId !== undefined) {
          apartmentService.getApartment(response.data.BuildingObjectId)
            .then(response => {
              setapartmentTitle(response.data.Address)
              buildingService.getBuilding(response.data.BuildingId)
                .then(response => {
                  setSavedBuildingTitle(response.data.Address)
                })
            })
        }
      })
      .catch(e => {
        console.log(e);
      });

    var sensor_details = document.getElementsByClassName("sensor-details")[index].id;
    setActive(true);
    setClickedSensor(sensor_details)
  }

  const handleClickOpen = (sensor) => {
    setOpen(true);
    ishanldeDelete(sensor)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    dispatch(deleteSensor(hanldeDelete));
  };

  const sortList = () => {
    setSort(!sort)
  };

  //get building options
  let buildingoptions = buildings.map(function (item) {
    return { value: item.BuildingId, label: item.BuildingId + "-" + item.Address };
  })

  //get building options
  let apartmentoptions = buildingApartments.map(function (item) {
    return { value: item.ApartmentId, label: item.ApartmentId + "-" + item.Address };
  })

  const resetFilters = () => {
    isresetFilter(true)
    setSearchTitle("");
    dispatch(findSensorsByFilter("", "", ""));
    setActive(false);
  };

  var sortJsonArray = require('sort-json-array');
  return (
    <>
      <div className="wrapper list">
        <div className="page-title">
          <h1>Sensors List</h1>
        </div>
        <div className="create-table full-width list-items sensor">
          <div className="create-row">
            <div className="create-cell">
              <div className="float-left full-width">
                <div className="float-left search-box">
                  <Input
                    type="text"
                    placeholder="Search..."
                    value={searchTitle}
                    OnChangeHandler={onChangeSearchTitle}
                  />
                </div>
                <div className="float-left filter">
                  <DropDown
                    key={resetFilter ? buildingFilter : null}
                    items={buildingoptions}
                    onChange={(e) => {
                      if (/\d/.test(e.value)) { //if user selected an building
                        setbuildingApartments([]);
                        setbuildingFilter(e.value)
                        dispatch(findSensorsByFilter(searchTitle, e.value, apartmentFilter));
                        buildingService.getBuilding(e.value)
                          .then(response => {
                            if (response.data.Apartments !== undefined) {
                              setbuildingApartments(response.data.Apartments);
                            }
                          })
                          .catch(e => {
                            console.log(e);
                          });
                      }
                      setActive(false);
                    }
                    }
                    name="BuildingId"
                    defaultOption={{ label: "Select Building", value: 0 }}
                  />
                </div>
                <div className="float-left filter">
                  <DropDown
                    key={resetFilter ? apartmentFilter : null}
                    items={apartmentoptions}
                    onChange={(e) => {
                      if (/\d/.test(e.value)) { //if user selected an building
                        setapartmentFilter(e.value)
                        dispatch(findSensorsByFilter(searchTitle, buildingFilter, e.value));
                      }
                      setActive(false);
                    }
                    }
                    name="BuildingId"
                    defaultOption={{ label: "Select Aparment", value: 0 }}
                  />
                </div>
               
                <div className="float-right add-icon">
                  <NavLink to={{ pathname: "/sensors/add-sensor" }} activeClassName="active-menu">
                    <AddCircleOutlineIcon className="hover-icon" fontSize="large" onClick={() =>
                      dispatch({
                        type: CLEAR_MESSAGE
                      })} ></AddCircleOutlineIcon>
                  </NavLink>
                </div>
                <div className="float-right reset-icon">
                  < AutorenewIcon fontSize="large" onClick={() => resetFilters()}></AutorenewIcon>
                </div>
              </div>
              <div className="float-left full-width">
                <div className="float-left list-item title ID">ID#<div className="sort-icon" onClick={() => { sortList() }}><SyncAltIcon></SyncAltIcon></div></div>
                <div className="float-left list-item title address">Serail Number</div>
                <div className="float-left list-item title">Category</div>
                <div className="float-left list-item title">Manufacturer</div>
                <div className="float-left list-item title">Module Type</div>
              </div>
              {
                sort ? console.log("sort", sortJsonArray(sensors, 'SensorId', 'des')) : console.log("sort", sortJsonArray(sensors, 'SensorId', 'asc'))
              }
              {sensors && sensors.length > 0 &&
                sensors.map((sensor, index) => (
                  <>
                    <div className={"float-left full-width list-row " + (clickedSensor == index && active ? 'active' : "")} key={index} >
                      <div className="float-left list-item ID"> {sensor.SensorId}</div>
                      <div className="float-left list-item address"> {sensor.SerialNumber}</div>
                      <div className="float-left list-item"> {sensor.CategoryName}</div>
                      <div className="float-left list-item"> {sensor.Manufacturer}</div>
                      <div className="float-left list-item"> {sensor.TechnicalDisplayName}</div>
                      <div className="float-right info-icon sensor-details" id={index}> <InfoIcon className="hover-icon" onClick={() => gotoSensorInfo(sensor, index)}></InfoIcon></div>
                      <div className="float-right info-icon">
                        <DeleteIcon className="hover-icon" onClick={() => handleClickOpen(sensor.SensorId)}></DeleteIcon>
                      </div>
                      <div className="float-right info-icon">
                        <NavLink to={"/sensors/sensor/" + sensor.SensorId} activeClassName="active-menu">
                          <EditIcon className="hover-icon" onClick={() =>
                            dispatch({
                              type: CLEAR_MESSAGE
                            })} />
                        </NavLink>
                      </div>
                      <div id={index} className={"details-block float-left full-width sensor " + (clickedSensor == index && active ? 'active' : "")} >
                        <div className="details-inner">
                          <div className="details-title"> <h4>Sensor Details</h4></div>
                          <div>
                            <Label>
                              <strong>Serial Number:</strong>
                            </Label>
                            {sensor.SerialNumber}
                          </div>
                          <div>
                            <Label>
                              <strong>Category:</strong>
                            </Label>
                            {sensor.CategoryName}
                          </div>
                          <div>
                            <Label>
                              <strong>Manufacturer:</strong>
                            </Label>
                            {sensor.Manufacturer}
                          </div>
                          <div>
                            <Label>
                              <strong>Module Type:</strong>
                            </Label>
                            {sensor.TechnicalDisplayName}
                          </div>
                          <div>
                            <Label>
                              <strong>Building:</strong>
                            </Label>
                            {buildingTitle}
                          </div>
                          <div>
                            <Label>
                              <strong>Apartment:</strong>
                            </Label>
                            {apartmentTitle}
                          </div>
                          <div>
                            <Label>
                              <strong>Room:</strong>
                            </Label>
                            {roomTitle}
                          </div>
                          <div>
                            <Label>
                              <strong>Controller:</strong>
                            </Label>
                            {controllerTitle}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete?"}</DialogTitle>
                  <DialogContent>
                  </DialogContent>
                  <DialogActions>
                    <Button OnClickEvent={() => handleClose()} >
                      No
                    </Button>
                    <Button OnClickEvent={() => handleConfirm()} >
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SensorsList;
