import {
    CREATE_ROOM,
    GET_ROOMS,
    UPDATE_ROOM,
    DELETE_ROOM,
  } from "../actions/types";
  
  const initialState = [];
  
  function roomReducer(rooms = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CREATE_ROOM:
        return [...rooms, payload];
  
      case GET_ROOMS:
        return payload;
  
      case UPDATE_ROOM:
        return rooms.map((room) => {
          if (room.RoomId === payload.RoomId) {
            return {
              ...room,
              ...payload,
            };
          } else {
            return room;
          }
        });
  
      case DELETE_ROOM:
        return rooms.filter(({ RoomId }) => RoomId !== payload.RoomId);
  
      default:
        return rooms;
    }
  };
  
  export default roomReducer;
  
