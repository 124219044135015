import {
    CREATE_SENSOR,
    GET_SENSORS,
    UPDATE_SENSOR,
    DELETE_SENSOR,
    GET_MODULE_TYPES
  } from "./types";
  
  import sensorService from "../../services/sensorService";
  
  export const createSensor = (ControllerId, BuildingObjectId, RoomId,TypeId, Url, Username, Password,SerialNumber, Manufacturer, TechnicalDisplayName, LayDisplayName) => async (dispatch) => {
    try {
      const res = await sensorService.createSensor({ ControllerId, BuildingObjectId, RoomId,TypeId, Url, Username, Password,SerialNumber, Manufacturer, TechnicalDisplayName, LayDisplayName});

      dispatch({
        type: CREATE_SENSOR,
        payload: res.data,
      });

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
  
    }
  };
  
  export const retrieveSensors = () => async (dispatch: any) => {
  
    try {
      const res = await sensorService.getSensors();
      dispatch({
        type: GET_SENSORS,
        payload: res.data,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const updateSensor = (SensorId, data) => async (dispatch: any) => {
    try {
      const res = await sensorService.updateSensor(SensorId, data);
      dispatch({
        type: UPDATE_SENSOR,
        payload: data,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      console.log("error",err);
      return Promise.reject(err);
    }
  };
  
  export const deleteSensor = (SensorId) => async (dispatch: any) => {
    try {
      await sensorService.removeSensor(SensorId);
      dispatch({
        type: DELETE_SENSOR,
        payload: { SensorId },
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  export const findSensorsByFilter = (SearchTerm,BuildingId,ApartmentId) => async (dispatch: any) => {
    try {
      const res = await sensorService.findByFilter(SearchTerm,BuildingId,ApartmentId);
      dispatch({
        type: GET_SENSORS,
        payload: res.data,
      });
   
    } catch (err) {
      console.log(err);
    }
  };
  
  export const retrieveModuleTypes = () => async (dispatch: any) => {
    try {
      const res = await sensorService.getModuleTypes();
  
      dispatch({
        type: GET_MODULE_TYPES,
        payload: res.data,
      });
    
    } catch (err) {
      console.log(err);
    }
  };