//declare actions types

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const GET_BUILDINGS = 'GET_BUILDINGS';
export const CREATE_BUILDING = "CREATE_BUILDING";
export const UPDATE_BUILDING = "UPDATE_BUILDING";
export const DELETE_BUILDING = "DELETE_BUILDING";

export const GET_APARTMENTS = 'GET_APARTMENTS';
export const CREATE_APARTMENT = "CREATE_APARTMENT";
export const UPDATE_APARTMENT = "UPDATE_APARTMENT";
export const DELETE_APARTMENT = "DELETE_APARTMENT";
export const GET_STRUCTURES = 'GET_STRUCTURES';

export const GET_ROOMS = 'GET_ROOMS';
export const CREATE_ROOM = "CREATE_ROOM";
export const UPDATE_ROOM = "UPDATE_ROOM";
export const DELETE_ROOM = "DELETE_ROOM";

export const GET_SENSORS = 'GET_SENSORS';
export const CREATE_SENSOR = "CREATE_SENSOR";
export const UPDATE_SENSOR = "UPDATE_SENSOR";
export const DELETE_SENSOR = "DELETE_SENSOR";
export const GET_CONTROLLERS ="GET_CONTROLLERS";
export const GET_MODULE_TYPES ="GET_MODULE_TYPES";
export const CREATE_CONTROLLER ="CREATE_CONTROLLER";


//building object Structure - bos
export const GET_BOS = 'GET_BOS';
export const CREATE_BOS = "CREATE_BOS";
export const UPDATE_BOS= "UPDATE_BOS";
export const DELETE_BOS= "DELETE_BOS";

//user info
export const GET_USER_INFO = 'GET_USER_INFO';
