import "./Button.css";

export interface ButtonProps {
  OnClickEvent?: any;
  children?: React.ReactNode,
  type?:any
}

const Button: React.FC<ButtonProps> = ({
  OnClickEvent
  ,children
}) => {
  return <button className="btn-style" onClick={OnClickEvent}>{children}</button>;
};

export default Button;
