import "./Input.css";

export interface InputProps {
  id?: string;
  type?: string;
  value?: any;
  defaultValue?: any;
  placeholder?: string;
  OnChangeHandler?: any;
  name?: string;
  onBlur?:any
}

const Input: React.FC<InputProps> = ({
  id,
  type,
  value,
  placeholder,
  OnChangeHandler,
  name,
  onBlur
}) => {
  return (
    <input className="txt-style"
      id={id}
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={OnChangeHandler}
      name={name}
      onBlur={onBlur}
    />
  );
};

export default Input;
