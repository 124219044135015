import * as yup from 'yup'

export const sensorSchema = yup.object().shape({
    ControllerId: yup.number().required('Please select a controller')
    .typeError('Please select a controller'),
    BuildingObjectId: yup.number().required('Please select an apartment')
    .typeError('Please select an apartment'),
    RoomId: yup.number().required('Please select a room')
    .typeError('Please select a room'),
    TypeId: yup.number().required('Please select a type')
    .typeError('Please select a type'),
    SerialNumber: yup.string().required("Serial Number is required"),
    Manufacturer: yup.string().required("Manufacturer is required"),
    TechnicalDisplayName: yup.string().required("Sensor Name/Desingation is required")
})

