import {ASSETS_API} from "../global";

const getBuildings = () => {
  return ASSETS_API.get(`Building/V2/GetBuildings/`);
};

const getBuilding = BuildingId => {
  return ASSETS_API.get(`Building/V2/GetBuilding/${BuildingId}`);
};

const updateBuilding = (BuildingId, data) => {
    return ASSETS_API.put(`Building/V2/EditBuilding/${BuildingId}`, data);
};

const create = data => {
  return ASSETS_API.post("Building/V2/PostBuilding", data);
};

const removeBuilding = BuildingId => {
  return ASSETS_API.delete(`Building/V2/DeleteBuilding/${BuildingId}`);
};

const findByAddress = Address => {
  return ASSETS_API.get(`Building/V2/GetBuildings?buildingQueryParameters.address=${Address}`);
};

export default {
  getBuildings,
  getBuilding,
  updateBuilding,
  create,
  removeBuilding,
  findByAddress
};
