import {
    CREATE_BOS,
    GET_BOS,
    UPDATE_BOS,
    DELETE_BOS,
  } from "../actions/types";
  
  const initialState = [];
  
  function buildingObjectStructureReducer(structures = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CREATE_BOS:
        return [...structures, payload];
  
      case GET_BOS:
        return payload;
  
      case UPDATE_BOS:
        return structures.map((structure) => {
          if (structure.StructureId === payload.StructureId) {
            return {
              ...structure,
              ...payload,
            };
          } else {
            return structure;
          }
        });
  
      case DELETE_BOS:
        return structures.filter(({ StructureId }) => StructureId !== payload.StructureId);
  
  
      default:
        return structures;
    }
  };
  
  export default buildingObjectStructureReducer;
  
  