import {ASSETS_API} from "../global";

const getRooms = () => {
  return ASSETS_API.get(`Room/V2/GetRooms`);
};

const getRoom = RoomId => {
  return ASSETS_API.get(`Room/V2/${RoomId}/GetRoom/`);
};

const updateRoom = (RoomId, data) => {
    return ASSETS_API.put(`Room/V2/EditRoom/${RoomId}`, data);
};

const create = data => {
  return ASSETS_API.post("Room/V2/PostRoom", data);
};

const removeRoom = RoomId=> {
  return ASSETS_API.delete(`Room/V2/DeleteRoom/${RoomId}`);
};

const findByName = Name => {
  return ASSETS_API.get(`Room/V2/GetRooms?roomQueryParameters.name=${Name}`);
};

export default {
  getRooms,
  getRoom,
  updateRoom,
  create,
  removeRoom,
  findByName
};
