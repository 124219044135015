import {
    CREATE_ROOM,
    GET_ROOMS,
    UPDATE_ROOM,
    DELETE_ROOM,
  } from "./types";
  
  import roomService from "../../services/roomService";
  
  export const createRoom = (name) => async (dispatch) => {
    try {
      const res = await roomService.create({ name });
  
      dispatch({
        type: CREATE_ROOM,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
  
    }
  };
  
  export const retrieveRooms = () => async (dispatch: any) => {
  
    try {
      const res = await roomService.getRooms();
  
      dispatch({
        type: GET_ROOMS,
        payload: res.data,
      });
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const updateRoom = (RoomId, data) => async (dispatch: any) => {
    try {
      const res = await roomService.updateRoom(RoomId, data);
  
      dispatch({
        type: UPDATE_ROOM,
        payload: data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
     
      return Promise.reject(err);
    }
  };
  
  export const deleteRoom = (RoomId) => async (dispatch: any) => {
    try {
      await roomService.removeRoom(RoomId);
      dispatch({
        type: DELETE_ROOM,
        payload: { RoomId },
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  export const findRoomsByName = (Name) => async (dispatch: any) => {
    try {
      const res = await roomService.findByName(Name);
  
      dispatch({
        type: GET_ROOMS,
        payload: res.data,
      });
   
    } catch (err) {
      console.log(err);
    }
  };
  
  
  