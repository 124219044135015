import "./Navbar.css";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { LogOutAction } from "../../store/actions/authAction";
import Button from "../../components/Button/Button";
import { NavLink } from 'react-router-dom';
import svgLogo from "../../assets/svg_logo.png";
import { CLEAR_MESSAGE } from "../../store/actions/types";

export interface NavBarProps { }

const Navbar: React.FC<NavBarProps> = () => {

  const dispatch = useDispatch<any>();
  const history = useHistory();

  const logoutBtnHandler = () => {
    dispatch(LogOutAction());
    dispatch({
      type: CLEAR_MESSAGE,
    });
    history.push("/");
    window.location.replace(window.location.href)
  };

  return (
    <>
      <div className="wrapper header">
        <div className="create-table full-width">
          <div className="create-row">
            <div className="create-cell">
              <div className="full-width float-left">
                <nav>
                  <div className={"logo-container float-left"}>
                    <a
                      href="/"
                    >
                      <img src={svgLogo} className={"logo"} alt="SVG company logo" />
                    </a>
                  </div>
                  <div className="menu-items-container float-right">
                    <div className="float-right">

                      {
                        localStorage.getItem("user") !== null ?

                          (
                            <>
                              <NavLink exact to={{ pathname: "/home" }} activeClassName="active-menu" className="menu-item">
                                Home
                              </NavLink>
                              {/*  <NavLink  to={{ pathname: "/buildings" }}  activeClassName="active-menu" className="menu-item">
                            Buildings
                          </NavLink >
                          <NavLink to={{ pathname: "/building-object-structures" }} activeClassName="active-menu" className="menu-item">
                            Building Object Structures
                          </NavLink>
                          <NavLink to={{ pathname: "/apartments" }} activeClassName="active-menu" className="menu-item">
                            Apartments
                          </NavLink>*/}
                              <NavLink to={{ pathname: "/sensors" }} activeClassName="active-menu" className="menu-item">
                                Sensors
                              </NavLink>
                            </>
                          ) :""}
                      { localStorage.getItem("user") !== null ? (
                        <Link
                          to={{ pathname: "/" }}
                          className="menu-item"
                          onClick={logoutBtnHandler}
                        >
                          <Button>Log out</Button>
                        </Link>
                      ):""}

                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
