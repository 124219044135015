import { useState, useEffect } from "react";
import sensorService from "../../services/sensorService";
import { retrieveModuleTypes, updateSensor } from '../../store/actions/sensorAction'
import { useDispatch, useSelector } from "react-redux";
import { SET_MESSAGE } from '../../store/actions/types'
import { useHistory } from "react-router";
import { Formik, Form, ErrorMessage } from "formik";
import { retrieveBuildings } from "../../store/actions/buildingAction";
import { sensorSchema } from "../../Validations/sensorValidation";
import { retrieveApartments } from "../../store/actions/apartmentAction";
import apartmentService from "../../services/apartmentService";
import buildingService from "../../services/buildingService";

//components
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import DropDown from "../../components/DropDown/DropDown";

//material ui
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { retrieveRooms } from "../../store/actions/roomAction";

export interface SensorProps { }

const Sensor = (props) => {
    const initialSensorState = {
        SensorId: null,
        BuildingId: 0,
        ControllerId: '',
        BuildingObjectId: 0,
        RoomId: 0,
        TypeId: 0,
        Url: "to be filled later",
        Username: "to be filled later",
        Password: "to be filled later",
        SerialNumber: "",
        Manufacturer: "",
        TechnicalDisplayName: "",
        LayDisplayName: "to be filled later",
    };

    const buildings = useSelector((state: any) => state.buildingReducer);
    const apartments = useSelector((state: any) => state.apartmentReducer);
    const rooms = useSelector((state: any) => state.roomReducer);
    const moduleTypes = useSelector((state: any) => state.moduleTypeReducer);
    const { message } = useSelector((state: any) => state.MessageReducer);

    const [currentSensor, setCurrentSensor] = useState(initialSensorState);
    const [selectedBuilding, setSelectedBuilding] = useState("");
    const [selectedApartment, setSelectedApartment] = useState("");
    const [selectedRoom, setSelectedRoom] = useState("");
    const [selectedModuleType, setSelectedModuleType] = useState("");

    const [apartmentRooms, setApartmentRooms] = useState([]);
    const [savedApartmentRoom, setSavedApartmentRoom] = useState([]);
    const [buildingApartments, setBuildingApartments] = useState([]);
    const [savedBuildingApartment, setSavedBuildingApartment] = useState("");
    const [availableApartments, isavailableApartments] = useState(false);
    const [buildingSelected, isbuildingSelected] = useState(false);
    const [error, setError] = useState("");
    const [controllerId, setControllerId] = useState("");

    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(retrieveBuildings());
        dispatch(retrieveApartments());
        dispatch(retrieveRooms());
        dispatch(retrieveModuleTypes());
    }, [dispatch]);


    let previousSavedBuilding;
    let previousSavedApartment;
    const getSensor = (id) => {
        sensorService.getSensor(id)
            .then(response => {
                setCurrentSensor(response.data);
                //get Apartment saved value
                previousSavedApartment = apartments.filter(item => item.ApartmentId == response.data.BuildingObjectId).map(function (item) {
                    return { value: item.ApartmentId, label: item.ApartmentId + "-" + item.Address };
                })

                localStorage.setItem('previousApartment', JSON.stringify(previousSavedApartment));
                if (response.data.BuildingObjectId !== undefined) {
                    apartmentService.getApartment(response.data.BuildingObjectId)
                        .then(response => {
                            //get Building saved value
                            previousSavedBuilding = buildings.filter(item => item.BuildingId == response.data.BuildingId).map(function (item) {
                                return { value: item.BuildingId, label: item.BuildingId + "-" + item.Address };
                            })
                            localStorage.setItem('previousBuilding', JSON.stringify(previousSavedBuilding));

                            setSavedApartmentRoom(response.data.Rooms);
                            setSavedBuildingApartment(response.data.BuildingId)

                            buildingService.getBuilding(response.data.BuildingId)
                                .then(response => {
                                    setBuildingApartments(response.data.Apartments);
                                })
                        })
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    useEffect(() => {
        getSensor(props.match.params.id);
    }, [props.match.params.id]);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setCurrentSensor({ ...currentSensor, [name]: value });
    };

    let history = useHistory();
    const goBack = () => {
        history.goBack()
    };

    const handleApartmentClick = () => {
        if (availableApartments == false && buildingSelected == false && currentSensor.BuildingId != undefined && localStorage.getItem("previousBuilding") !== null) {
            setError("please select a building")
        }
    }

    //get building options
    let buildingoptions = buildings.map(function (item) {
        return { value: item.BuildingId, label: item.BuildingId + "-" + item.Address };
    })

    //get apartment options
    let apartmentoptions;
    if (buildingApartments !== undefined) {
        apartmentoptions = buildingApartments.map(function (item) {
            return { value: item.ApartmentId, label: item.ApartmentId + "-" + item.Address };
        })
    }

    //get room options
    let roomoptions = apartmentRooms.map(function (item) {
        return { value: item.RoomId, label: item.RoomId + "-" + item.Name };
    })

    //get module types options
    let moduleTypesoptions = moduleTypes.filter(item => item.CategoryCode == "011" || item.CategoryCode == "012").map(function (item) {
        return { value: item.CategoryId, label: item.CategoryName };
    })

    //get selected Building value
    let selectedBuildingValue = buildings.filter(item => item.BuildingId == selectedBuilding).map(function (item) {
        return { value: item.BuildingId, label: item.BuildingId + "-" + item.Address };
    })

    //get selected Apartment value
    let selectedApartmentValue = buildingApartments.filter(item => item.ApartmentId == selectedApartment).map(function (item) {
        return { value: item.ApartmentId, label: item.ApartmentId + "-" + item.Address };
    })

    //get selected Room value
    let selectedRoomValue = apartmentRooms.filter(item => item.RoomId == selectedRoom).map(function (item) {
        return { value: item.RoomId, label: item.RoomId + "-" + item.Name };
    })

    //get selected module type value
    let selectedModuleTypeValue = moduleTypes.filter(item => item.CategoryId == selectedModuleType).map(function (item) {
        return { value: item.CategoryId, label: item.CategoryName };
    })
   
    //get Room saved value
    let previousSavedRoom = rooms.filter(item => item.RoomId == currentSensor.RoomId).map(function (item) {
        return { value: item.RoomId, label: item.Name };
    })
    localStorage.setItem('previousRoom', JSON.stringify(previousSavedRoom));

    //get Module Type saved value
    let previousSavedModuleType = moduleTypes.filter(item => item.CategoryId == currentSensor.TypeId).map(function (item) {
        return { value: item.CategoryId, label: item.CategoryName };
    })
    localStorage.setItem('previousModuleType', JSON.stringify(previousSavedModuleType));

    return (
        <>
            <div className="main-container">
                <div className="submit-form">
                    <KeyboardBackspaceIcon className="hover-icon" fontSize="large" onClick={goBack}></KeyboardBackspaceIcon>
                    <div className="page-title">Edit Sensor</div>
                    <Formik
                        initialValues={currentSensor}
                        onSubmit={() => {
                            dispatch(updateSensor(currentSensor.SensorId, currentSensor))
                                .then(response => {
                                    if (response.status != 200) {
                                        dispatch({
                                            type: SET_MESSAGE,
                                            payload: "An error occurred!",
                                        });
                                    }
                                    else {
                                        dispatch({
                                            type: SET_MESSAGE,
                                            payload: "The sensor was updated successfully!",
                                        });
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                    if (e.response.status == 401) {
                                        dispatch({
                                            type: SET_MESSAGE,
                                            payload: "You are not authorized to do such action",
                                        });
                                    }
                                });
                        }}
                        validationSchema={sensorSchema}
                        enableReinitialize={true}
                    >
                        {(formProps) => {
                            const { values } = formProps;
                            return (
                                <Form>
                                    <div className="form-input">
                                        <label>Building</label>
                                        <DropDown
                                            items={buildingoptions}
                                            selectedItem={selectedBuilding ? selectedBuildingValue : JSON.parse(localStorage.getItem('previousBuilding'))}
                                            onChange={(e) => {
                                                const selectedBuilding = e.value;
                                                setSelectedBuilding(selectedBuilding);
                                                setCurrentSensor({ ...currentSensor, BuildingId: selectedBuilding });
                                                setBuildingApartments([]);
                                                setApartmentRooms([]);
                                                setSavedApartmentRoom([]);
                                                if (/\d/.test(e.value)) { //if user selected an building
                                                    localStorage.removeItem("previousApartment");
                                                    localStorage.removeItem("previousRoom");
                                                    isbuildingSelected(true);
                                                    buildingService.getBuilding(e.value)
                                                        .then(response => {
                                                            if (response.data.Apartments !== undefined) {
                                                                setBuildingApartments(response.data.Apartments);
                                                            }
                                                            else {
                                                                setError("You cannot add a sensor to this building")
                                                            }
                                                            if (response.data.Apartments.length == 0) {
                                                                isavailableApartments(false);
                                                            }
                                                            else {
                                                                isavailableApartments(true);
                                                            }
                                                            setError("")
                                                            setControllerId(response.data.ControllerId)
                                                            formProps.setFieldValue('ControllerId', response.data.ControllerId);
                                                        })
                                                        .catch(e => {
                                                            console.log(e);
                                                        });
                                                }
                                                setCurrentSensor({ ...currentSensor, BuildingId: e.value });
                                                formProps.setFieldValue("BuildingId", e.value)
                                            }
                                            }
                                            name={"buildings"}
                                            defaultOption="Building"
                                        />
                                        <div className="error-message"><ErrorMessage name="BuildingId" /></div>
                                        <div className="error-message"> {error}</div>

                                    </div>
                                    <div className="form-input" onClick={handleApartmentClick}>
                                        <label>Apartment</label>
                                        <DropDown
                                            key={selectedBuilding}
                                            items={apartmentoptions}
                                            selectedItem={selectedApartment ? selectedApartmentValue : JSON.parse(localStorage.getItem('previousApartment'))}
                                            onChange={(e) => {
                                                localStorage.removeItem("previousRoom");
                                                const selectedApartment = e.value;
                                                setSelectedApartment(selectedApartment);
                                                setCurrentSensor({ ...currentSensor, BuildingObjectId: selectedApartment });
                                                setApartmentRooms([]);
                                                setSavedApartmentRoom([]);
                                                if (/\d/.test(e.value)) { //if user selected an apartment
                                                    apartmentService.getApartment(e.value)
                                                        .then(response => {
                                                            setApartmentRooms(response.data.Rooms);
                                                        })
                                                        .catch(e => {
                                                            console.log(e);
                                                        });
                                                }
                                                setCurrentSensor({ ...currentSensor, BuildingObjectId: e.value });
                                                formProps.setFieldValue("BuildingObjectId", e.value)
                                            }
                                            }
                                            name={"apartments"}
                                            defaultOption="Apartment"
                                        />
                                        <div className="error-message"><ErrorMessage name="BuildingObjectId" /></div>
                                    </div>
                                    <div className="form-input">
                                        <label>Room</label>
                                        <DropDown
                                            key={selectedApartment}
                                            items={roomoptions}
                                            selectedItem={selectedRoom ? selectedRoomValue : JSON.parse(localStorage.getItem('previousRoom'))}
                                            onChange={(e) => {
                                                formProps.setFieldValue("RoomId", e.value)
                                                setCurrentSensor({ ...currentSensor, RoomId: e.value });
                                            }
                                            }
                                            name={"rooms"}
                                            defaultOption="room"
                                        />
                                        <div className="error-message"><ErrorMessage name="RoomId" /></div>
                                    </div>
                                    <div>
                                        <Input
                                            type="hidden"
                                            id="ControllerId"
                                            value={controllerId}
                                            name="ControllerId"
                                        />
                                    </div>
                                    <div className="form-input">
                                        <label>Type</label>
                                        <DropDown
                                            selectedItem={selectedModuleType ? selectedModuleTypeValue : JSON.parse(localStorage.getItem('previousModuleType'))}
                                            items={moduleTypesoptions}
                                            onChange={(e) => {
                                                setSelectedModuleType(selectedModuleType);
                                                setCurrentSensor({ ...currentSensor, TypeId: e.value });
                                                formProps.setFieldValue("TypeId", e.value
                                                )
                                            }}
                                            name={"Types"}
                                            defaultOption="type"

                                        />
                                        <div className="error-message"><ErrorMessage name="TypeId" /></div>
                                    </div>
                                    <div className="form-input">
                                        <label htmlFor="SerialNumber">Serial Number</label>
                                        <Input
                                            type="text"
                                            id="SerialNumber"
                                            name="SerialNumber"
                                            value={values.SerialNumber}
                                            OnChangeHandler={handleInputChange}
                                        />
                                        <div className="error-message"><ErrorMessage name="SerialNumber" /></div>
                                    </div>

                                    <div className="form-input">
                                        <label htmlFor="Manufacturer">Manufacturer</label>
                                        <Input
                                            type="text"
                                            id="Manufacturer"
                                            name="Manufacturer"
                                            value={values.Manufacturer}
                                            OnChangeHandler={handleInputChange}
                                        />
                                        <div className="error-message"><ErrorMessage name="Manufacturer" /></div>
                                    </div>
                                    <div className="form-input">
                                        <label htmlFor="TechnicalDisplayName">Sensor Name/Designation</label>
                                        <Input
                                            type="text"
                                            id="TechnicalDisplayName"
                                            name="TechnicalDisplayName"
                                            value={values.TechnicalDisplayName}
                                            OnChangeHandler={handleInputChange}
                                        />
                                        <div className="error-message"><ErrorMessage name="TechnicalDisplayName" /></div>
                                    </div>
                                    <Button type="submit" > Update
                                    </Button>
                                </Form>
                            );
                        }}
                    </Formik>
                    <p>{message}</p>
                </div>
            </div>
        </>
    );
};

export default Sensor;
