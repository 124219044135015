import {
  CREATE_APARTMENT,
  GET_APARTMENTS,
  UPDATE_APARTMENT,
  DELETE_APARTMENT,
  GET_STRUCTURES,
} from "../actions/types";

const initialState = [];

function apartmentReducer(apartments = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_APARTMENT:
      return [...apartments, payload];

    case GET_APARTMENTS:
      return payload;

    case UPDATE_APARTMENT:
      return apartments.map((apartment) => {
        if (apartment.ApartmentId === payload.ApartmentId) {
          return {
            ...apartment,
            ...payload,
          };
        } else {
          return apartment;
        }
      });

    case DELETE_APARTMENT:
      return apartments.filter(({ ApartmentId }) => ApartmentId !== payload.ApartmentId);

    case GET_STRUCTURES:
      return payload;

    default:
      return apartments;
  }
};

export default apartmentReducer;

