import { AxiosError } from "axios";
import { ASSETS_API } from "../global";

const API_URL = "token"
const Login = async (loginForm: any) => {

  var formData = new URLSearchParams();
  formData.append("username", loginForm.username);
  formData.append("password", loginForm.password);
  formData.append("grant_type", "password");

  return ASSETS_API.post(API_URL, formData, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  }).then((response) => {
    if (response.data.access_token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  }).catch((error) => {
    const err = error as AxiosError
    if (err.response) {
      return Promise.reject();
    }
  })
};

export const Logout = () => {
  localStorage.removeItem("user");
};

const getUserInfo = () => {
  return ASSETS_API.get(`User/GetInformation`);
};

const AuthService = {
  Login,
  Logout,
  getUserInfo
};

export default AuthService;

