import { useState, useEffect } from "react";
import { createSensor, retrieveModuleTypes } from "../../store/actions/sensorAction";
import { useDispatch, useSelector } from "react-redux";
import { sensorSchema } from "../../Validations/sensorValidation";
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../store/actions/types'
import { useFormik } from "formik";
import { retrieveBuildings } from "../../store/actions/buildingAction";
import apartmentService from "../../services/apartmentService";
import { useHistory } from "react-router";
import buildingService from "../../services/buildingService";
import { Link } from "react-router-dom";

//components
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import DropDown from "../../components/DropDown/DropDown";

//material ui
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const AddSensor = () => {
  const { message } = useSelector((state: any) => state.MessageReducer);
  const moduleTypes = useSelector((state: any) => state.moduleTypeReducer);
  const buildings = useSelector((state: any) => state.buildingReducer);

  const [submitted, setSubmitted] = useState(false);
  const [buildingApartments, setbuildingApartments] = useState([]);
  const [apartmentRooms, setApartmentRooms] = useState([]);
  const [availableApartments, isavailableApartments] = useState(false);
  const [buildingSelected, isbuildingSelected] = useState(false);
  const [error, setError] = useState("");
  const [controllerId, setControllerId] = useState("");

  const [previousBuilding, setpreviousBuilding] = useState("");
  const [previousApartment, setpreviousApartment] = useState("");
  const [previousRoom, setpreviousRoom] = useState("");

  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(retrieveBuildings());
    dispatch(retrieveModuleTypes());
  }, [dispatch]);

  const { handleSubmit, handleChange, values, touched, errors, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        ControllerId: '',
        BuildingId: '',
        BuildingObjectId: null,
        RoomId: null,
        TypeId: null,
        Url: "to be filled later",
        Username: "to be filled later",
        Password: "to be filled later",
        SerialNumber: "",
        Manufacturer: "",
        TechnicalDisplayName: "",
        LayDisplayName: "to be filled later",
      },
      enableReinitialize: false,
      validationSchema: sensorSchema,
      onSubmit: ({ ControllerId, BuildingObjectId, RoomId, TypeId, Url, Username, Password, SerialNumber, Manufacturer, TechnicalDisplayName, LayDisplayName }) => {
        dispatch(createSensor(ControllerId, BuildingObjectId, RoomId, TypeId, Url, Username, Password, SerialNumber, Manufacturer, TechnicalDisplayName, LayDisplayName))
          .then(data => {
            if (data.status !== 200) {
              dispatch({
                type: SET_MESSAGE,
                payload: "An error occurred!",
              });
            }
            else {
              dispatch({
                type: CLEAR_MESSAGE,
              });
              setpreviousBuilding(values.BuildingId)
              setpreviousApartment(values.BuildingObjectId)
              setpreviousRoom(values.RoomId)

              setSubmitted(true);
            }
          })
          .catch(e => {
            console.log(e);
            if (e.response.status == 401) {
              dispatch({
                type: SET_MESSAGE,
                payload: "You are not authorized to do such action",
              });
            }
          });
      },
    });

  const newSensor = (previousBuilding, previousApartment, previousRoom) => {
    setFieldValue("BuildingId", previousBuilding)
    setFieldValue("BuildingObjectId", previousApartment)
    setFieldValue("RoomId", previousRoom)
    setFieldValue("SerialNumber", "")
    setFieldValue("Manufacturer", "")
    setFieldValue("TechnicalDisplayName", "")
    setSubmitted(false);
  };

  const handleApartmentClick = () => {
    if (availableApartments == false && buildingSelected == false) {
      setError("please select a building")
    }
  }

  let history = useHistory();
  const goBack = () => {
    history.goBack()
  };

  //get building options
  let buildingoptions = buildings.map(function (item) {
    return { value: item.BuildingId, label: item.BuildingId + "-" + item.Address };
  })

  //use static values: in this case we need only smoke sensors + heating system)
  let moduleTypesoptions = moduleTypes.filter(item => item.CategoryCode == "011" || item.CategoryCode == "012").map(function (item) {
    return { value: item.CategoryId, label: item.CategoryName };
  })

  //get apartment options
  let apartmentoptions;
  if (buildingApartments !== undefined) {
    apartmentoptions = buildingApartments.map(function (item) {
      return { value: item.ApartmentId, label: item.ApartmentId + "-" + item.Address };
    })
  }

  //get room options
  let roomoptions = apartmentRooms.map(function (item) {
    return { value: item.RoomId, label: item.RoomId + "-" + item.Name };
  })

  //get previous Building saved value
  let previousSavedBuilding = buildings.filter(item => item.BuildingId == previousBuilding).map(function (item) {
    return { value: item.BuildingId, label: item.BuildingId + "-" + item.Address };
  })

  //get previous Apartment saved value
  let previousSavedApartment = buildingApartments.filter(item => item.ApartmentId == previousApartment).map(function (item) {
    return { value: item.ApartmentId, label: item.ApartmentId + "-" + item.Address };
  })

  //get previous Room saved value
  let previousSavedRoom = apartmentRooms.filter(item => item.RoomId == previousRoom).map(function (item) {
    return { value: item.RoomId, label: item.RoomId + "-" + item.Name };
  })

  return (
    <>
      <div className="main-container">
        <div className="submit-form">
          <KeyboardBackspaceIcon className="hover-icon" fontSize="large" onClick={goBack}></KeyboardBackspaceIcon>
          <div className="page-title">Add a Sensor</div>
          {submitted ? (
            <div>
              <div> <h4>Sensor has been added successfully!</h4></div>
              <div className="btn-container">
                <Button OnClickEvent={(e) => { newSensor(previousBuilding, previousApartment, previousRoom) }}>
                  Add Another
                </Button>
                <Link to="/sensors" className="btn-2">
                  <Button>
                    All Sensors
                  </Button>
                </Link>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="form-input">
                <label>Building</label>
                <DropDown
                  items={buildingoptions}
                  onChange={(e) => {
                    isbuildingSelected(true)
                    if (/\d/.test(e.value)) { //if user selected an building
                      setbuildingApartments([]);
                      buildingService.getBuilding(e.value)
                        .then(response => {
                          if (response.data.Apartments !== undefined) {
                            setbuildingApartments(response.data.Apartments);
                          }
                          else {
                            setError("You cannot add a sensor to this building")
                          }
                          if (response.data.Apartments.length == 0) {
                            isavailableApartments(false);
                          }
                          else {
                            isavailableApartments(true);
                          }
                          setError("")
                          setControllerId(response.data.ControllerId)
                          setFieldValue('ControllerId', response.data.ControllerId);
                        })
                        .catch(e => {
                          console.log(e);

                        });
                      setFieldValue('BuildingId', e.value);
                    }
                  }
                  }
                  onBlur={handleBlur}
                  name="BuildingId"
                  defaultOption={previousBuilding ? previousSavedBuilding : { label: "Select Building", value: 0 }}
                />
                {touched.BuildingId && errors.BuildingId ? (
                  <div className="error-message">
                    {errors.BuildingId}
                  </div>
                ) : null}
                <div className="error-message"> {error}</div>
              </div>
              <div className="form-input" onClick={handleApartmentClick}>
                <label>Apartments</label>
                <DropDown
                  key={values.BuildingId}
                  items={apartmentoptions}
                  onChange={(e) => {
                    setApartmentRooms([]);
                    if (apartmentoptions !== null) {
                      if (/\d/.test(e.value)) { //if user selected an apartment
                        apartmentService.getApartment(e.value)
                          .then(response => {
                            setApartmentRooms(response.data.Rooms);
                          })
                          .catch(e => {
                            console.log(e);
                          });
                        setFieldValue('BuildingObjectId', e.value);
                      }
                    }
                  }
                  }
                  onBlur={handleBlur}
                  value={values.BuildingObjectId}
                  name="BuildingObjectId"
                  defaultOption={previousApartment ? previousSavedApartment : { label: "Select Apartment", value: 0 }}
                />
                {touched.BuildingObjectId && errors.BuildingObjectId ? (
                  <div className="error-message">
                    {errors.BuildingObjectId}
                  </div>
                ) : null}
              </div>
              <div className="form-input">
                <label>Rooms</label>
                <DropDown
                  key={values.BuildingObjectId}
                  items={roomoptions}
                  onChange={(e) => {
                    if (/\d/.test(e.value)) { //if user selected an apartment
                      setFieldValue('RoomId', e.value);
                    }
                  }
                  }
                  onBlur={handleBlur}
                  value={values.RoomId}
                  defaultOption={previousRoom ? previousSavedRoom : { label: "Select Room", value: 0 }}
                  name="RoomId"
                />
                {touched.RoomId && errors.RoomId ? (
                  <div className="error-message">
                    {errors.RoomId}
                  </div>
                ) : null}
              </div>
              <div>
                <Input
                  type="hidden"
                  id="ControllerId"
                  value={controllerId}
                  OnChangeHandler={handleChange}
                  name="ControllerId"
                  onBlur={handleBlur}
                />
              </div>
              <div className="form-input">
                <label>Types</label>
                <DropDown
                  items={moduleTypesoptions}
                  onChange={(e) => {
                    if (/\d/.test(e.value)) { //if user selected a type
                      setFieldValue('TypeId', e.value);
                    }
                  }
                  }
                  onBlur={handleBlur}
                  value={values.TypeId}
                  defaultOption={{ label: "Select Type", value: 0 }}
                  name="TypeId"
                />
                {touched.TypeId && errors.TypeId ? (
                  <div className="error-message">
                    {errors.TypeId}
                  </div>
                ) : null}
              </div>
              <div className="form-input">
                <label htmlFor="SerialNumber">SerialNumber</label>
                <Input
                  type="text"
                  id="SerialNumber"
                  value={values.SerialNumber}
                  OnChangeHandler={handleChange}
                  name="SerialNumber"
                  onBlur={handleBlur}
                />
                {touched.SerialNumber && errors.SerialNumber ? (
                  <div className="error-message">
                    {errors.SerialNumber}
                  </div>
                ) : null}
              </div>
              <div>
                <Input
                  type="hidden"
                  id="Url"
                  value={values.Url}
                  OnChangeHandler={handleChange}
                  name="Url"
                  onBlur={handleBlur}
                />
              </div>
              <div>
                <Input
                  type="hidden"
                  id="Username"
                  value={values.Username}
                  OnChangeHandler={handleChange}
                  name="Username"
                  onBlur={handleBlur}
                />
              </div>
              <div>
                <Input
                  type="hidden"
                  id="Password"
                  value={values.Password}
                  OnChangeHandler={handleChange}
                  name="Password"
                  onBlur={handleBlur}
                />
              </div>
              <div className="form-input">
                <label htmlFor="Manufacturer">Manufacturer</label>
                <Input
                  type="text"
                  id="Manufacturer"
                  value={values.Manufacturer}
                  OnChangeHandler={handleChange}
                  name="Manufacturer"
                  onBlur={handleBlur}
                />
                {touched.Manufacturer && errors.Manufacturer ? (
                  <div className="error-message">
                    {errors.Manufacturer}
                  </div>
                ) : null}
              </div>
              <div className="form-input">
                <label htmlFor="TechnicalDisplayName">Sensor Name/Designation</label>
                <Input
                  type="text"
                  id="TechnicalDisplayName"
                  value={values.TechnicalDisplayName}
                  OnChangeHandler={handleChange}
                  name="TechnicalDisplayName"
                  onBlur={handleBlur}
                />
                {touched.TechnicalDisplayName && errors.TechnicalDisplayName ? (
                  <div className="error-message">
                    {errors.TechnicalDisplayName}
                  </div>
                ) : null}
              </div>
              <div className="form-input">
                <Input
                  type="hidden"
                  id="LayDisplayName"
                  value={values.LayDisplayName}
                  OnChangeHandler={handleChange}
                  name="LayDisplayName"
                  onBlur={handleBlur}
                />
              </div>
              <Button type="submit">
                Submit
              </Button>
              <div className="error-message">
                <label>{message}</label>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default AddSensor;



