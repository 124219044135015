import "./home.css";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch} from "react-redux";
import AuthService from "../../services/authService";
import { retrieveUserInfo } from "../../store/actions/authAction";
import { Link } from "react-router-dom";

//material ui
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

export interface HomeProps { }

const Home: React.FC<HomeProps> = () => {

  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(retrieveUserInfo());
  }, [dispatch]);

  AuthService.getUserInfo()
    .then(response => {
      setfirstName(response.data.Firstname)
      setlastName(response.data.Lastname)

    })
    .catch(e => {
      console.log(e);
    });

  return (
    <div className="wrapper">
      <div className="home-container">
       <div className="welcome-text"> Welcome {firstName} {lastName} </div>
       <div className="welcome-text s2">To add a sensor go to <span className="sensors-link"><Link to ="/sensors"> Sensors</Link></span> and click on the add sign<span className="add-sign"><AddCircleOutlineIcon/></span></div>
      </div>
    </div>

  );
};

export default Home;

