//Combine Reducers
import { combineReducers } from "redux";
import LoginReducer from "./authReducer";
import MessageReducer from "./messageReducer";
import buildingReducer from "./buildingReducer"
import apartmentReducer from "./apartmentReducer"
import roomReducer from "./roomReducer"
import sensorReducer from "./sensorReducer"
import moduleTypeReducer from "./moduleTypeReducer"
import controllerReducer from "./controllerReducer"
import buildingObjectStructureReducer from "./buildingObjectStructureReducer"

export default combineReducers({
  LoginReducer,
  MessageReducer,
  buildingReducer,
  apartmentReducer,
  roomReducer,
  sensorReducer,
  moduleTypeReducer,
  controllerReducer,
  buildingObjectStructureReducer
});

export type RootState = ReturnType<typeof combineReducers>;
