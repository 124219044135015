import './DropDown.css'
import Select from "react-select";

export interface DropDownProps {
  name?: any,
  items?: any,
  selectedItem?: any,
  onChange?: any,
  value?: any
  onBlur?: any,
  defaultOption?: any
  isMulti?: boolean,
  key?:any
}

const DropDown: React.FC<DropDownProps> = ({
  name,
  items,
  selectedItem,
  onChange,
  onBlur,
  defaultOption,
  isMulti,
  key
}) => {
  {
    return ((() => {
      if (isMulti) {
        return (
          <Select
            name={name}
            value={selectedItem}
            onChange={onChange}
            options={items}
            isMulti={isMulti}
          />
        )
      } else
        return (
          <>
            <div>
              <Select
                key={key}
                name={name}
                value={selectedItem}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={defaultOption}
                options={items}>
              </Select>
            </div>
          </>
        )
    })());
  };
}
  export default DropDown;
