import {ASSETS_API} from "../global";

const getSensors = () => {
  return ASSETS_API.get(`Sensor/V2/GetSensors?sensorQueryParameters.moduleTypeCodes=011%2C012`);
};

const getSensor = SensorId => {
  return ASSETS_API.get(`Sensor/V2/GetSensor/${SensorId}`);
};

const updateSensor = (SensorId, data) => {
    return ASSETS_API.put(`Sensor/V2/EditSensor/${SensorId}`, data);
};

const createSensor = data => {
  return ASSETS_API.post("Sensor/V2/PostSensor", data);
};

const removeSensor = SensorId => {
  return ASSETS_API.delete(`Sensor/V2/DeleteSensor/${SensorId}`);
};

const findByFilter = (SearchTerm,BuildingId,ApartmentId) => {
  return ASSETS_API.get(`Sensor/V2/GetSensors?sensorQueryParameters.searchTerm=${SearchTerm}&sensorQueryParameters.moduleTypeCodes=011%2C012&sensorQueryParameters.buildingId=${BuildingId}&sensorQueryParameters.apartmentId=${ApartmentId}`);
};

const getSystemTypes = () => {
  return ASSETS_API.get(`Sensor/V2/GetSystemTypes`);
};

const getController = ControllerId => {
  return ASSETS_API.get(`Sensor/V2/GetController/${ControllerId}`);
};

const getModuleTypes = () => {
  return ASSETS_API.get(`Configuration/V2/GetModuleCategories`);
};

export default {
  getSensors,
  getSensor,
  updateSensor,
  createSensor,
  removeSensor,
  findByFilter,
  getSystemTypes,
  getController,
  getModuleTypes,
};
