import {
   
    GET_MODULE_TYPES
  } from "../actions/types";
  
  const initialState = [];
  
  function moduleTypeReducer(moduleTypes = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
   
        case GET_MODULE_TYPES:
          return payload;
  
      default:
        return moduleTypes;
    }
  };
  
  export default moduleTypeReducer;
  
  