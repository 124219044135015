import {
  CREATE_SENSOR,
  GET_SENSORS,
  UPDATE_SENSOR,
  DELETE_SENSOR,
  GET_MODULE_TYPES
} from "../actions/types";

const initialState = [];

function sensorReducer(sensors = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_SENSOR:
      return [...sensors, payload];

    case GET_SENSORS:
      return payload;

    case UPDATE_SENSOR:
      return sensors.map((sensor) => {
        if (sensor.SensorId === payload.SensorId) {
          return {
            ...sensor,
            ...payload,
          };
        } else {
          return sensor;
        }
      });

    case DELETE_SENSOR:
      return sensors.filter(({ SensorId }) => SensorId !== payload.SensorId);

    case GET_MODULE_TYPES:
      return payload;

    default:
      return sensors;
  }
};

export default sensorReducer;

